import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Masonry from 'react-masonry-component';

import { Title, Section, Box, Text, ButtonOutline, ListNav } from '../../components/Core';
import RotateImg from '../../components/RotateImg';
import { referenssit } from '../../data';
import { useWindowSize } from '../../hooks';
import { breakpoints } from '../../utils';

const WorkCard = styled(Box)``;
const WorkText = styled(Box)``;

const Grid = props => {
  const size = useWindowSize();

  const masonryOptions = {
    transitionDuration: 1000
  };

  return size.width < breakpoints.lg ? (
    <Masonry options={masonryOptions} className={'masonry-grid row'} {...props} />
  ) : (
    <Row {...props} className="justify-content-center" />
  );
};

const Works = () => {
  const [items, setItems] = useState([]);
  const [activeLink, setActiveLink] = useState('*');

  useEffect(() => {
    setItems(referenssit);
  }, []);

  const filterBy = cat => {
    if (cat === '*') {
      setActiveLink('*');
      setItems(referenssit);
    } else {
      const filteredItems = referenssit.filter(item => {
        return item.categories.indexOf(cat) !== -1;
      });
      setActiveLink(cat);
      setItems(filteredItems);
    }
  };

  return (
    <>
      {/* <!-- Works Area --> */}
      <Section className="position-relative">
        <Container>
          <Box mb="2.5rem" ml="-1.75rem" className="d-flex justify-content-center">
            <ListNav className="nav">
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === '*' ? 'active' : null
                  }`}
                  onClick={e => {
                    e.preventDefault();
                    filterBy('*');
                  }}
                >
                  Kaikki
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === 'kaidelasit' ? 'active' : null
                  }`}
                  onClick={e => {
                    e.preventDefault();
                    filterBy('kaidelasit');
                  }}
                >
                  Kaidelasit
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === 'terassi' ? 'active' : null
                  }`}
                  onClick={e => {
                    e.preventDefault();
                    filterBy('terassi');
                  }}
                >
                  Terassi
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === 'kylpyhuone' ? 'active' : null
                  }`}
                  onClick={e => {
                    e.preventDefault();
                    filterBy('kylpyhuone');
                  }}
                >
                  Kylpyhuone
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === 'parvekelasit' ? 'active' : null
                  }`}
                  onClick={e => {
                    e.preventDefault();
                    filterBy('parvekelasit');
                  }}
                >
                  Parvekelasit
                </a>
              </li>
            </ListNav>
          </Box>
        </Container>
        <Container fluid>
          <Grid>
            {items.map((item, index) => (
              <Col lg="3" md="6" key={index} className="filtr-item">
                <WorkCard className="position-relative" mb="30px">
                  <RotateImg link={item.link} imgSrc={item.thumbnail} />
                  <Box pt="2.125rem">
                    <WorkText className="overflow-hidden text-center">
                      <Title variant="cardLg">
                        <Link to={item.link}>{item.brand} </Link>
                      </Title>
                    </WorkText>
                  </Box>
                </WorkCard>
              </Col>
            ))}
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default Works;
