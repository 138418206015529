import React from 'react';
import { Box } from '../components/Core';
import { Element } from "react-scroll";
import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/landing4/Hero';
import Works from '../sections/grid-3-fluid/Works';
import CTA from '../sections/landing4/CTA';

const IndexPage = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Box>
          <Hero />
           <Element name="works">
            <Works />
          </Element>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default IndexPage;
