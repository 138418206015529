import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';

import { Title, Section, Box, Text, Button } from '../../components/Core';

import bgHero from '../../assets/image/png/dev-bg.png';
import { device } from '../../utils';

const SectionStyled = styled(Section)`
  &::before {
    opacity: 0.15;
    position: absolute;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: url(${bgHero}) center left no-repeat;
    background-size: cover;
    z-index: -1;
  }
  @media ${device.lg} {
    &::before {
      opacity: 0.15;
      position: absolute;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: url(${bgHero}) top center no-repeat;
      background-size: cover;
      z-index: -1;
    }
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled
        hero
        className="position-relative"
        pt={['100px', null, '75px', '100px']}
        pb={['50px', null, '150px']}
      >
        <Container>
          <Box className="d-flex flex-column align-items-center text-center">
            <Title variant="hero" mb="1.5rem">
              Rakkaudesta lasiin.
            </Title>
            <Text
              color="text"
              css={`
                line-height: 1.5;
              `}
              mb="2.5rem"
            >
              Suunnittelemme ja toteutamme kaikki lasiin ja lasirakentamiseen liittyvät lasityöt.{' '}
              <strong>Yli 38 vuoden kokemuksella.</strong>
            </Text>
            <Link to="works" spy={true} smooth={true} offset={-50} duration={1000}>
              <Button>Katso referenssejä</Button>
            </Link>
          </Box>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
