import imgT1 from '../assets/image/jpg/kaidelasitukset/img1.jpg';
import imgT2 from '../assets/image/jpg/parvekelasitukset/img2.jpg';
import imgT3 from '../assets/image/jpg/terassi/img1.jpg';
import imgT4 from '../assets/image/jpg/kylpyhuone/img1.jpg';

export const referenssit = [
  {
    brand: `Kaidelasit`,
    slug: `kaidelasit`,
    title: `Kaidelasit`,
    categories: [`kaidelasit`],
    thumbnail: imgT1,
    link: '/kaidelasit'
  },
  {
    brand: `Parvekelasit`,
    slug: `parvekelasit`,
    title: `Parvekelasit`,
    categories: [`parvekelasit`],
    thumbnail: imgT2,
    link: '/parvekelasit'
  },
  {
    brand: `Terassi`,
    slug: `terassi`,
    title: `Terassi`,
    categories: [`terassi`],
    thumbnail: imgT3,
    link: '/terassi'
  },
  {
    brand: `Kylpyhuone`,
    slug: `kylpyhuone`,
    title: `Kylpyhuone`,
    categories: [`kylpyhuone`],
    thumbnail: imgT4,
    link: '/kylpyhuone'
  }
];
